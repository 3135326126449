import { createTheme } from "@mui/material/styles";
export let theme = createTheme({
  palette: {
    primary: { main: "#2f54eb" },
    secondary: { main: "#20c788" },
    text: {
      dark: "#222222",
      lightdark: "#303030",
      danger: "#dc3545",
      success: "#28a745",
      red: "#ff0000",
      white: "#ffffff",
    },
    background: { primary: "#f0f5ff", secondary: "#20c788" },
  },
  typography: { fontFamily: '"Inter", sans-serif' },
});
theme = createTheme(theme, {
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          [theme.breakpoints.up("xs")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
          },
          [theme.breakpoints.up("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
          },
          [theme.breakpoints.up("md")]: {
            paddingLeft: "32px",
            paddingRight: "32px",
          },
          [theme.breakpoints.up("lg")]: {
            paddingLeft: "64px",
            paddingRight: "64px",
          },
          [theme.breakpoints.up("xl")]: {
            paddingLeft: "64px",
            paddingRight: "64px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: '"Inter", sans-serif',
          fontSize: "1.875rem",
          fontWeight: "700",
          color: "#222222",
          lineHeight: "1.2",
          [theme.breakpoints.up("xs")]: { fontSize: "1.188rem" },
          [theme.breakpoints.up("sm")]: { fontSize: "1.563rem" },
          [theme.breakpoints.up("md")]: { fontSize: "1.875rem" },
          [theme.breakpoints.up("lg")]: { fontSize: "1.875rem" },
        },
        h2: {
          fontFamily: '"Inter", sans-serif',
          fontSize: "1.75rem",
          fontWeight: "700",
          color: "#222222",
          lineHeight: "1.1",
          [theme.breakpoints.up("xs")]: { fontSize: "1.188rem" },
          [theme.breakpoints.up("sm")]: { fontSize: "1.563rem" },
          [theme.breakpoints.up("md")]: { fontSize: "1.75rem" },
          [theme.breakpoints.up("lg")]: { fontSize: "1.75rem" },
        },
        h3: {
          fontFamily: '"Inter", sans-serif',
          fontSize: "1.5rem",
          fontWeight: "700",
          color: "#222222",
          lineHeight: "1.1",
          [theme.breakpoints.up("xs")]: { fontSize: "1.188rem" },
          [theme.breakpoints.up("sm")]: { fontSize: "1.563rem" },
          [theme.breakpoints.up("md")]: { fontSize: "1.5rem" },
          [theme.breakpoints.up("lg")]: { fontSize: "1.5rem" },
        },
        h4: {
          fontFamily: '"Inter", sans-serif',
          fontSize: "1.375rem",
          fontWeight: "700",
          color: "#222222",
          lineHeight: "1.2",
          [theme.breakpoints.up("xs")]: { fontSize: "1.1rem" },
          [theme.breakpoints.up("sm")]: { fontSize: "1.1rem" },
          [theme.breakpoints.up("md")]: { fontSize: "1.375rem" },
          [theme.breakpoints.up("lg")]: { fontSize: "1.375rem" },
        },
        h5: {
          fontFamily: '"Inter", sans-serif',
          fontSize: "1.25rem",
          fontWeight: "700",
          color: "#222222",
          lineHeight: "1.2",
          [theme.breakpoints.up("xs")]: { fontSize: "1.1rem" },
          [theme.breakpoints.up("sm")]: { fontSize: "1.1rem" },
          [theme.breakpoints.up("md")]: { fontSize: "1.25rem" },
          [theme.breakpoints.up("lg")]: { fontSize: "1.25rem" },
        },
        h6: {
          fontFamily: '"Inter", sans-serif',
          fontSize: "1.063rem",
          fontWeight: "700",
          color: "#222222",
          lineHeight: "1.2",
          [theme.breakpoints.up("xs")]: { fontSize: "1rem" },
          [theme.breakpoints.up("sm")]: { fontSize: "1rem" },
          [theme.breakpoints.up("md")]: { fontSize: "1.063rem" },
          [theme.breakpoints.up("lg")]: { fontSize: "1.063rem" },
        },
        body1: { color: "#303030" },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "6px",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "13px",
          fontWeight: "600",
          maxHeight: "48px",
          textTransform: "capitalize",
          padding: "0.6rem 1rem",
          "&:hover": { backgroundColor: "#20c788" },
        },
        containedSecondary: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "6px",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "13px",
          fontWeight: "600",
          maxHeight: "48px",
          textTransform: "capitalize",
          padding: "0.6rem 1rem",
          backgroundColo: "#20c788",
          color: "#ffffff",
          "&:hover": { backgroundColor: "#2f54eb" },
        },
        outlinedPrimary: {
          textTransform: "capitalize",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "13px",
          fontWeight: "600",
          maxHeight: "48px",
        },
      },
    },
    MuiButtonBase: { defaultProps: { LinkComponent: "a" } },
    MuiInputBase: { styleOverrides: { root: { color: "#222" } } },
    MuiInputLabel: {
      styleOverrides: {
        root: { color: "#6c757d", opacity: "1", fontStyle: "italic" },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiFormLabel-root": {
            color: "#6c757d",
            opacity: "1",
            fontStyle: "italic",
          },
          "& .MuiInputBase-input": { color: "#222222" },
          "& .MuiOutlinedInput-input ": { color: "#222222" },
        },
      },
    },
  },
});
