import { createSlice } from "@reduxjs/toolkit";
const userSlice = createSlice({
  name: "user",
  initialState: { user: null },
  reducers: {
    loginUser: (e, s) => {
      e.user = s.payload;
    },
    submitEstimation: (e, s) => {
      e.user = s.payload;
    },
  },
});
export const selectUser = (e) => e.user.user;
export const { loginUser: loginUser, submitEstimation: submitEstimation } =
  userSlice.actions;
export default userSlice.reducer;