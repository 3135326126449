const logOutIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}/assets/logOut.svg`,
  simplicitySpeed = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/simplicitySpeed.svg`,
  totalFree = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/totalFree.svg`,
  optimalPrecision = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/optimalPrecision.svg`,
  extendedVisibility = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/extendedVisibility.svg`,
  agencyDetailBanner = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/agency-slider.webp`,
  comparateurDataNotFound = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurContentPageIcons/comparateurDataNotFound.svg`,
  announcesBg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/announcesBg.webp`,
  typeDetailPageIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/typesIcons/typeDetailPageIcon.svg`,
  surfaceDetailPageIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/typesIcons/surfaceDetailPageIcon.svg`,
  roomsDetailPageIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/typesIcons/roomsDetailPageIcon.svg`,
  bathroomsDetailPageIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/typesIcons/bathroomsDetailPageIcon.svg`,
  AppLogoWhite = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/logo-white.svg`,
  AppleStore = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/footer/AppleStore.svg`,
  Googleplay = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/footer/GooglePlay.svg`,
  FbMarketingPrn = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/footer/FbMarketingPrn.svg`,
  GoMarketingPrn = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/footer/GoMarketingPrn.svg`,
  vousEtesDetialPageImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/vousEtesDetialPageImg.svg`,
  contactAgencyLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/contactAgencyLogo.svg`,
  avitoLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/avito-logo.webp`,
  marocannonces = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/marocannonces.webp`,
  yakeeyLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/yakeey-logo.webp`,
  mubawabLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/mubawab-logo.webp`,
  saroutyLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/sarouty-logo.webp`,
  agenzLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/agenz-logo.webp`,
  surfaceIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/typesIcons/surfaceIcon.svg`,
  roomsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/typesIcons/roomsIcon.svg`,
  bathroomsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/typesIcons/bathroomsIcon.svg`,
  piecesIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/piecesIcon.svg`,
  Surface = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/Surface.svg`,
  Lit = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/Lit.svg`,
  HeartVecter = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/HeartVecter.svg`,
  MaisonsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/BarometreDesPrix/Images/MaisonsIcon.svg`,
  AppartementsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/BarometreDesPrix/Images/AppartementsIcon.svg`,
  msgTelIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/messageTelBtnIcon.svg`,
  eyeIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/eyeIcon.svg`,
  FaceBookIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/faceBookIcon.svg`,
  InstagramIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/instagramIcon.svg`,
  WhatsAppIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/whatsappIcon.svg`,
  CopyIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/copyIcon.svg`,
  LocationIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/locationIcon.svg`,
  TelephoneIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/telephoneIcon.svg`,
  WappIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/wappIcon.svg`,
  CestImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/undraw_Design.svg`,
  rightIconFull = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AvisClientsCard/righticon.svg`,
  PublishImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AnnoncesDeLocationCard/PublishImg.svg`,
  surfaceIcon1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AnnoncesDeVenteCard/surfaceIcon.svg`,
  roomsIcon1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AnnoncesDeVenteCard/roomsIcon.svg`,
  bathroomsIcon1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AnnoncesDeVenteCard/bathroomsIcon.svg`,
  imFolderUpload = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AvisClientsCard/uploadIcon.svg`,
  riDeleteBin6Line = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AvisClientsCard/deleteIcon.svg`,
  rightIconFull1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AvisClientsCard/righticon.svg`,
  EvaluerImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/EvaluerImg.svg`,
  estimation01 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/estimation01.webp`,
  EstResultAppartmenticon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/EstimationImmobiliere/EstResultAppartmenticon.svg`,
  DecouvrezLePrixImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/Evaluation/DecouvrezLePrixImg.svg`,
  Creche01 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche01.svg`,
  Creche02 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche02.svg`,
  Creche03 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche03.svg`,
  Creche04 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche04.svg`,
  Creche05 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche05.svg`,
  Estimervalue1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Estimervalue.svg`,
  evaluationsvg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/evaluation.webp`,
  EvaluerVotreAdresseImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/Evaluation/EvaluerVotreAdresseImg.svg`,
  Bus = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Bus.svg`,
  Metro = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Metro.svg`,
  Train = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Train.svg`,
  Tramway = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Tramway.svg`,
  VousProImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/Evaluation/VousProImg.svg`,
  logo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/logo.webp`,
  checkIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepFinal/checkIcon.svg`,
  GalleryIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepThree/galleryIcon.svg`,
  img1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img1.svg`,
  img2 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img2.svg`,
  img3 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img3.svg`,
  img4 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img4.svg`,
  img5 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img5.svg`,
  img6 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img6.svg`,
  img7 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img7.svg`,
  img8 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img8.svg`,
  img9 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img9.svg`,
  img10 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img10.svg`,
  img11 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img11.svg`,
  Cube = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/AjouterUneVisiteVirtuelle/cube.svg`,
  peopleIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/peopleIcon.svg`,
  PlusIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/PlusIcon.svg`,
  LoaderGif = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/loader.svg`,
  loginpageBg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/loginpage-bg.webp`,
  ArrowRightFooter = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/ArrowRightFooter.svg`,
  validationIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/signIn/validationIcon.svg`,
  facebookIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/signIn/facebookIcon.svg`,
  parCuri = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/contactPage/parCuri.svg`,
  parTel = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/contactPage/parTel.svg`,
  parunter = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/contactPage/parunter.svg`,
  Estimervalue = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/contactPage/contactPage.webp`,
  iconOne = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/announcesContentPageIcons/iconOne.svg`,
  iconTwo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/announcesContentPageIcons/iconTwo.svg`,
  iconThree = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/announcesContentPageIcons/iconThree.svg`,
  iconFour = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/announcesContentPageIcons/iconFour.svg`,
  iconFive = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/estimationContentPageIcons/iconFive.svg`,
  iconSix = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/estimationContentPageIcons/iconSix.svg`,
  telephoneIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/telephoneIcon.svg`,
  imgBg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurDagences/annuaireBg.webp`,
  MiseEnRelation = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurDagences/MiseEnRelation.svg`,
  AvisInfalsifiables = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurDagences/AvisInfalsifiables.svg`,
  PriseDeRendezvous = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurDagences/PriseDeRendezvous.svg`,
  BarometreDuImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/BarometreDuImg.webp`,
  ComparezPlusImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/ComparezPlusImg.webp`,
  LouerIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/AvezVousEnvie/LouerIcon.webp`,
  AcheterIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/AvezVousEnvie/AcheterIcon.webp`,
  CoursIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/AvezVousEnvie/CoursIcon.webp`,
  AssuranceIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/AvezVousEnvie/AssuranceIcon.webp`,
  EstimerLlaValeurImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/EstimerLlaValeurmg.webp`,
  EstimezLeImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/EstimezLeImg.webp`,
  UneExperienceImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/UneExperience.webp`,
  regions_geojson = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}geojson/regions.json`,
  provinces_geojson = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}geojson/provinces.json`,
  communes_geojson = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}geojson/city.json`,
  districts_geojson = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}geojson/district.json`,
  neighbourhoods_geojson = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}geojson/neighbourhood.json`,
  AgentsImmobilierContentData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/agentsImmobilierContentData.json`,
  AgentsImmobilierFaqData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/agentsImmobilierFaqData.json`,
  AgentsImmobilierTestimonialData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/agentsImmobilierTestimonialData.json`,
  AnnouncesContentData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/announcesContentData.json`,
  AnnouncesFaqData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/announcesFaqData.json`,
  AnnouncesTestimonialData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/announcesTestimonialData.json`,
  EstimationContentData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/estimationContentData.json`,
  EstimationFaqData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/estimationFaqData.json`,
  EstimationTestimonialData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/estimationTestimonialData.json`,
  HomeFaqData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/homeFaqData.json`,
  HomeTestimonialData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/homeTestimonialData.json`,
  MapMarker = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/favicon.png`,
  Heart = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/heart.svg`,
  RedHeart = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/red-heart.svg`,
  Share = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/share.svg`,
  ConnectiviteIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Connectivite.svg`,
  EducationIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Education.svg`,
  EnsoleillementIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Ensoleillement.svg`,
  LocationOnIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/LocationIcon.svg`,
  ProximiteIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Proximite.svg`,
  QualiteIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Qualite.svg`,
  ScoreIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Score.svg`,
  SecuriteIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Securite.svg`,
  TransportIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Transport.svg`,
  LocationSxIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/LocationSxIcon.svg`,
  Signal = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Signal.svg`,
  CapitalIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Capital.svg`,
  MILKIYAIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/MILKIYA.svg`,
  SERRAJIMMOBILIERIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/SERRAJIMMOBILIER.svg`,
  EnvalopIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Envalop.svg`,
  CollegeIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/College.svg`,
  EcoleMaternelleIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/EcoleMaternelle.svg`,
  LyceeIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Lycee.svg`,
  UniversiteIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Universite.svg`,
  BusIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Transport/Bus.svg`,
  TaxiIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/evaluation/Transport/Taxi.svg`,
  TableauDeBoard = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/buyerSellerMenuIcon/tableauDeBoard.svg`,
  Alertes = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/buyerSellerMenuIcon/alertes.svg`,
  MesAnnounces = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/buyerSellerMenuIcon/mesAnnounces.svg`,
  BiensEstimes = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/buyerSellerMenuIcon/biensEstimes.svg`,
  ProjectDeVente = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/buyerSellerMenuIcon/projectDeVente.svg`,
  ProjectDChat = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/buyerSellerMenuIcon/projectDChat.svg`,
  AnnouncesAimees = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/buyerSellerMenuIcon/announcesAimees.svg`,
  MonProfile = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/buyerSellerMenuIcon/monProfile.svg`,
  logOut = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/buyerSellerMenuIcon/logOut.svg`,
  defaultSliderImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/default-slider-img.png`,
  flagIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/BarometreDesPrix/Images/flag.svg`,
  UserProfile = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/user_profile_images/`;
export {
  CollegeIcon,
  EcoleMaternelleIcon,
  LyceeIcon,
  UniversiteIcon,
  BusIcon,
  TaxiIcon,
  CapitalIcon,
  MILKIYAIcon,
  SERRAJIMMOBILIERIcon,
  EnvalopIcon,
  Signal,
  LocationSxIcon,
  ConnectiviteIcon,
  EducationIcon,
  EnsoleillementIcon,
  LocationOnIcon,
  ProximiteIcon,
  QualiteIcon,
  ScoreIcon,
  SecuriteIcon,
  TransportIcon,
  AgentsImmobilierContentData,
  AgentsImmobilierFaqData,
  AgentsImmobilierTestimonialData,
  AnnouncesContentData,
  AnnouncesFaqData,
  AnnouncesTestimonialData,
  EstimationContentData,
  EstimationFaqData,
  EstimationTestimonialData,
  HomeFaqData,
  HomeTestimonialData,
  provinces_geojson,
  communes_geojson,
  regions_geojson,
  districts_geojson,
  neighbourhoods_geojson,
  logOutIcon,
  simplicitySpeed,
  totalFree,
  optimalPrecision,
  extendedVisibility,
  agencyDetailBanner,
  comparateurDataNotFound,
  announcesBg,
  peopleIcon,
  PlusIcon,
  LoaderGif,
  loginpageBg,
  ArrowRightFooter,
  validationIcon,
  facebookIcon,
  parCuri,
  parTel,
  parunter,
  Estimervalue,
  iconOne,
  iconTwo,
  iconThree,
  iconFour,
  iconFive,
  iconSix,
  telephoneIcon,
  imgBg,
  MiseEnRelation,
  AvisInfalsifiables,
  PriseDeRendezvous,
  BarometreDuImg,
  ComparezPlusImg,
  LouerIcon,
  AcheterIcon,
  CoursIcon,
  AssuranceIcon,
  EstimerLlaValeurImg,
  EstimezLeImg,
  UneExperienceImg,
  typeDetailPageIcon,
  surfaceDetailPageIcon,
  roomsDetailPageIcon,
  bathroomsDetailPageIcon,
  AppLogoWhite,
  AppleStore,
  Googleplay,
  FbMarketingPrn,
  GoMarketingPrn,
  vousEtesDetialPageImg,
  contactAgencyLogo,
  avitoLogo,
  marocannonces,
  yakeeyLogo,
  mubawabLogo,
  saroutyLogo,
  agenzLogo,
  surfaceIcon,
  roomsIcon,
  bathroomsIcon,
  piecesIcon,
  Surface,
  Lit,
  HeartVecter,
  MaisonsIcon,
  AppartementsIcon,
  msgTelIcon,
  eyeIcon,
  FaceBookIcon,
  InstagramIcon,
  WhatsAppIcon,
  CopyIcon,
  LocationIcon,
  TelephoneIcon,
  WappIcon,
  CestImg,
  rightIconFull,
  PublishImg,
  surfaceIcon1,
  roomsIcon1,
  bathroomsIcon1,
  imFolderUpload,
  riDeleteBin6Line,
  rightIconFull1,
  EvaluerImg,
  estimation01,
  EstResultAppartmenticon,
  DecouvrezLePrixImg,
  Creche01,
  Creche02,
  Creche03,
  Creche04,
  Creche05,
  Estimervalue1,
  evaluationsvg,
  EvaluerVotreAdresseImg,
  Bus,
  Metro,
  Train,
  Tramway,
  VousProImg,
  logo,
  checkIcon,
  GalleryIcon,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  Cube,
  MapMarker,
  Heart,
  RedHeart,
  Share,
  TableauDeBoard,
  Alertes,
  MesAnnounces,
  BiensEstimes,
  ProjectDeVente,
  ProjectDChat,
  AnnouncesAimees,
  MonProfile,
  logOut,
  defaultSliderImg,
  flagIcon,
  UserProfile,
};